import { Link } from 'gatsby';
import { setLightness } from 'polished';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { colors } from '../styles/colors';
import { outer, inner } from '../styles/shared';
import config from '../website-config';



import { SocialLink } from '../styles/shared';
import Reddit from '../components/icons/reddit';
import Pinterest from '../components/icons/pinterest';
import Facebook from '../components/icons/facebook';
import Twitter from '../components/icons/twitter';
import Linkedin from '../components/icons/linkedin';
import Medium from '../components/icons/medium';


const SocialLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  a:last-of-type {
    padding-right: 20px;
  }
`;




const SiteFooter = css`
  position: relative;
  padding-top: 20px;
  padding-bottom: 60px;
  color: #fff;
  background: ${setLightness('0.0015', colors.darkgrey)};
`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;
  a {
    color: rgba(255, 255, 255, 0.7);
  }
  a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const SiteFooterNav = styled.nav`
  display: flex;

  a {
    position: relative;
    margin-left: 20px;
  }

  a:before {
    content: '';
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
  }

  a:first-of-type:before {
    display: none;
  }
  @media (max-width: 650px) {
    a:first-child {
      margin-left: 0;
    }
  }
`;

export const Footer: React.FC = () => {
  return (
    <footer css={[outer, SiteFooter]}>
      <div css={[inner, SiteFooterContent]}>
        <section className="copyright">
          <Link to="/">{config.title}</Link> &copy; {new Date().getFullYear()}{' '}
          {config.footer && (
            <Link to="/">
              | {config.title} {config.footer}
            </Link>
          )}
        </section>
        <SiteFooterNav>
          <Link to="/">Latest Posts</Link>
          {config.facebook && (
            <a href='https://stackshare.io/companies/notnoob' target="_blank" rel="noopener noreferrer">
              StackShare
            </a>
          )}
          {config.twitter && (
            <a href='https://twitter.com/sudoaptupgrade/' target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          )}

          <a href="http://notnoob.com" target="_blank" rel="noopener noreferrer">
            Blog
          </a>

          <a href="https://www.lokerhq.com" target="_blank" rel="noopener noreferrer">
            Jobs
          </a>
          
          <a href="https://insight.notnoob.com" target="_blank" rel="noopener noreferrer">
            Insight
          </a>

          <a href="/rss.xml">RSS</a>

        </SiteFooterNav>

        <SocialLinks>
            {config.facebook && (
              <a
                css={SocialLink}
                href={config.facebook}
                target="_blank"
                title="Facebook"
                rel="noopener noreferrer"
              >
                <Facebook />
              </a>
            )}
            {config.twitter && (
              <a
                css={SocialLink}
                href={config.twitter}
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter />
              </a>
            )}
            {config.linkedin && (
              <a
                css={SocialLink}
                href={config.linkedin}
                title="Linkedin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin />
              </a>
            )}
            {config.medium && (
              <a
                css={SocialLink}
                href={config.medium}
                title="Medium"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Medium />
              </a>
            )}            
            {config.pinterest && (
              <a
                css={SocialLink}
                href={config.pinterest}
                title="Pinterest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Pinterest />
              </a>
            )}            
            {config.reddit && (
              <a
                css={SocialLink}
                href={config.reddit}
                title="Reddit"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Reddit />
              </a>
            )}      
          </SocialLinks>

      </div>
    </footer>
  );
};

export default Footer;
